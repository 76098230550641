export const getHoursBetweenDates = (
  datesArray: [string, string]
): string[] => {
  const startDate = new Date(datesArray[0])
  const endDate = new Date(datesArray[1])
  const hours: string[] = []

  startDate.setMinutes(0)
  startDate.setSeconds(0)
  startDate.setMilliseconds(0)

  // Adjust the end date to include the hours of the last day
  endDate.setDate(endDate.getDate() + 1)
  endDate.setHours(0)
  endDate.setMinutes(0)
  endDate.setSeconds(0)
  endDate.setMilliseconds(0)

  while (startDate < endDate) {
    // Check if the current date is still within the range
    if (startDate < new Date(datesArray[1])) {
      hours.push(startDate.toISOString())
    }
    startDate.setTime(startDate.getTime() + 60 * 60 * 1000)
  }
  return hours
}

export const formatDateHourly = (inputDateStr) => {
  const date = new Date(inputDateStr)
  const day = date.getDate().toString().padStart(2, '0')
  const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(
    date
  )
  const hours = date.getHours().toString().padStart(2, '0')
  const minutes = date.getMinutes().toString().padStart(2, '0')
  const formattedDate = `${day}.${month} ${hours}:${minutes}`
  return formattedDate
}

export const getMonthsBetweenDates = (dates: [string, string]): string[] => {
  const startDate = new Date(dates[0])
  const endDate = new Date(dates[1])

  const result: string[] = []

  let currentDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1)

  const isFirstDayOfMonth = startDate.getDate() === 1

  if (!isFirstDayOfMonth) {
    currentDate.setMonth(currentDate.getMonth() + 1)
  }

  while (currentDate < endDate) {
    const isoString = currentDate.toISOString()
    const localTimeOffset = currentDate.getTimezoneOffset() * 60000
    const localTimeString = new Date(
      currentDate.getTime() - localTimeOffset
    ).toISOString()
    result.push(localTimeString)
    currentDate.setMonth(currentDate.getMonth() + 1)
  }
  return result
}

export const getDatesBetween = (date: [string, string]): string[] => {
  const startDate = new Date(date[0])
  const endDate = new Date(date[1])
  const dates: string[] = []

  startDate.setHours(0, 0, 0, 0)
  endDate.setHours(0, 0, 0, 0)

  const currentDate = new Date(startDate)
  while (currentDate < endDate) {
    const localTimeOffset = currentDate.getTimezoneOffset() * 60000
    const localTimeString = new Date(
      currentDate.getTime() - localTimeOffset
    ).toISOString()
    dates.push(localTimeString)
    currentDate.setDate(currentDate.getDate() + 1)
  }
  return dates
}

export const formatDate = (dateStr: string) => {
  const date = new Date(dateStr)
  const day = date.getDate()
  const month = date.toLocaleString('default', { month: 'short' })
  return `${day}. ${month}`
}

export const removeDuplicatesScales = (
  arr: { group: string; unit: string }[]
) => {
  let uniqueArray: { group: string; unit: string }[] = []
  arr.forEach(function (item) {
    let isUnique = true
    uniqueArray.forEach(function (uniqueItem) {
      if (uniqueItem.group === item.group && uniqueItem.unit === item.unit) {
        isUnique = false
      }
    })
    if (isUnique) {
      uniqueArray.push(item)
    }
  })
  return uniqueArray
}

export const createScaleObject = (arr) => {
  let output = {}
  arr.forEach((item, idx) => {
    let key = item.group + ' ' + item.unit
    output[key] = {
      beginAtZero: true,
      type: 'linear',
      position: idx % 2 === 0 ? 'left' : 'right',
      ticks: {
        beginAtZero: true,
        callback: (value, index, ticks) => {
          value = shortenedNumber(value)
          return value + ' ' + item.unit.toUpperCase()
        }
      },
      title: {
        display: true,
        text: `${item.group}`
      },
      grid: { display: idx === 0 ? true : false }
    }
  })
  output['x'] = {
    ticks: { beginAtZero: true },
    grid: {
      display: false,
      drawBorder: false
    }
  }
  return output
}

export const organizeDatasets = (
  data,
  labels,
  projectsCountingData: { name: string }[] | null = null
) => {
  const dataSetsFromDB = data.map((currData, index) => {
    if (currData.data.length === 0) return

    if (currData.value_type === 'Accumulative') {
      const currProjectCounting =
        projectsCountingData?.find((d) => d.name === currData.name) || null
      return getDataSetForAccumulative(currData, labels, currProjectCounting)
    } else {
      return getDataSetsForInstantainus(currData, index, labels)
    }
  })

  return {
    dataSetsFromDB
  }
}

export const getDataSetForAccumulative = (obj, labels, projectsCountingData?) => {
  let organizedProjectCountingData: null | string[] = null
  if (projectsCountingData) {
    organizedProjectCountingData = handleProjectCountingDataSet(
      projectsCountingData,
      labels
    )
  }
  let alternativeData
  if (obj.value_type === 'Instantainus') {
    alternativeData = organizeTableDataForInstantainus(obj.data)
  }
  let currChartType = obj.chart_type.toLowerCase()
  let lineTensionForCurrChart = 0
  let fill = false
  let typeOfChart = 'line'
  if (currChartType === 'line') {
    lineTensionForCurrChart = 0
    fill = false
  } else if (currChartType === 'spline') {
    lineTensionForCurrChart = 0.2
    fill = false
  } else if (currChartType === 'areaspline') {
    lineTensionForCurrChart = 0.2
    fill = true
  } else {
    lineTensionForCurrChart = 0
    typeOfChart = 'bar'
  }

  let finalData =
    alternativeData === undefined ? handleDataSet(obj, labels) : alternativeData
  return {
    backgroundColor: obj.color
      ? getColorWithOpacity(obj.color, 0.5)
      : getRandomColor(),
    borderColor: obj.color || getRandomColor(),
    pointBackgroundColor: obj.color || getRandomColor(),
    data: finalData,
    label: obj.name,
    lineTension: lineTensionForCurrChart,
    type: typeOfChart,
    yAxisID: obj.group_name + ' ' + obj.unit,
    unit: obj.unit,
    group: obj.group_name,
    value_type: obj.value_type,
    fill: fill,
    pointRadius: 2,
    tooltip: {
      callbacks: {
        label: function (context, index) {
          let label = context.dataset.label || ''
          let value = context.parsed.y.toLocaleString('en-US', {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0
          })
          let returnString = `${label}: ${value} (${
            obj.unit.length === 1 ? obj.unit.toUpperCase() : obj.unit
          })`
          if (organizedProjectCountingData) {
            returnString += ` - ${
              organizedProjectCountingData[context.dataIndex]
            } Projects`
          }
          return returnString
        }
      }
    }
  }
}

export const getDataSetsForInstantainus = (obj, index, labels) => {
  let minAvgData = handleDataSetInstantainus(obj, labels, 'min')
  let maxAvgData = handleDataSetInstantainus(obj, labels, 'max')
  // @ts-ignore
  let avgData = handleDataSetInstantainus(obj, labels)
  // @ts-ignore
  let mainColor = colorForChart[index]
  if (!mainColor) {
    mainColor = getRandomColor()
  }
  let colors = getLighterAndDarkerColors(mainColor)
  let currChartType = obj.chart_type.toLowerCase()
  let lineTensionForCurrChart = 0
  let fill = false
  let typeOfChart = 'line'
  if (currChartType === 'line') {
    lineTensionForCurrChart = 0
    fill = false
  } else if (currChartType === 'spline') {
    lineTensionForCurrChart = 0.2
    fill = false
  } else if (currChartType === 'areaspline') {
    lineTensionForCurrChart = 0.2
    fill = true
  } else if (currChartType === 'bar') {
    lineTensionForCurrChart = 0
    typeOfChart = 'bar'
  } else {
    lineTensionForCurrChart = 0
    typeOfChart = 'line'
  }
  let datasetsArray: object[] = []
  for (let i = 0; i < colors.length; i++) {
    let label
    let data
    if (i === 0) {
      label = `Min. ${obj.name}`
      data = minAvgData
    } else if (i === 1) {
      label = `AVG. ${obj.name}`
      data = avgData
    } else {
      label = `Max. ${obj.name}`
      data = maxAvgData
    }
    datasetsArray.push({
      backgroundColor: getColorWithOpacity(colors[i], 0.5),
      borderColor: colors[i],
      pointBackgroundColor: colors[i],
      data: data,
      label: label,
      lineTension: lineTensionForCurrChart,
      type: typeOfChart,
      yAxisID: obj.group_name + ' ' + obj.unit,
      unit: obj.unit,
      group: obj.group_name,
      value_type: obj.value_type,
      fill: fill,
      pointRadius: 2,
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || ''
            let value = context.parsed.y.toLocaleString('en-US', {
              maximumFractionDigits: 2,
              minimumFractionDigits: 0
            })
            return `${label}: ${value} (${
              obj.unit.length === 1 ? obj.unit.toUpperCase() : obj.unit
            })`
          }
        }
      }
    })
  }
  return datasetsArray
}

export const getDataSetForSpecialAccumulative = (obj, labels) => {
  const groupedData = {}

  obj.data.forEach((objData) => {
    if (!groupedData[objData.id]) {
      groupedData[objData.id] = {
        id: objData.id,
        data: [],
        chart_type: obj.chart_type,
        color: obj.color,
        group_name: obj.group_name,
        name: `${objData.id}: ${obj.name}`,
        unit: obj.unit,
        value_type: obj.value_type,
      }
    }
    groupedData[objData.id].data.push({
      value: objData.value,
      ts_from: objData.ts_from,
    })
  })

  const newArray = Object.values(groupedData)

  if (newArray.length > 1) {
    const check = newArray.map((item) => {
      return getDataSetForSpecialAccumulative(item, labels)
    })
    return check
  } else {
    let alternativeData
    let currChartType = obj.chart_type.toLowerCase()
    let lineTensionForCurrChart = 0
    let fill = false
    let typeOfChart = 'line'
    if (currChartType === 'line') {
      lineTensionForCurrChart = 0
      fill = false
    } else if (currChartType === 'spline') {
      lineTensionForCurrChart = 0.2
      fill = false
    } else if (currChartType === 'areaspline') {
      lineTensionForCurrChart = 0.2
      fill = true
    } else {
      lineTensionForCurrChart = 0
      typeOfChart = 'bar'
    }

    return {
      backgroundColor: obj.color
        ? getColorWithOpacity(obj.color, 0.5)
        : getRandomColor(),
      borderColor: obj.color || getRandomColor(),
      pointBackgroundColor: obj.color || getRandomColor(),
      data:
        alternativeData === undefined
          ? handleDataSet(obj, labels)
          : alternativeData,
      label: obj.name,
      lineTension: lineTensionForCurrChart,
      type: typeOfChart,
      yAxisID: obj.group_name + ' ' + obj.unit,
      unit: obj.unit,
      group: obj.group_name,
      value_type: obj.value_type,
      fill: fill,
      pointRadius: 2,
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || ''
            let value = context.parsed.y.toLocaleString('en-US', {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            })
            return `${label}: ${value} (${
              obj.unit.length === 1 ? obj.unit.toUpperCase() : obj.unit
            })`
          },
        },
      },
    }
  }
}

function handleDataSetInstantainus(obj, labels, type: string | null) {
  let data = []
  obj.data.forEach((d) => {
    const dateIndex = labels.findIndex((l) => l === d.ts_from)
    if (type === 'min') {
      // @ts-ignore
      data[dateIndex] = d.min_value
    } else if (type === 'max') {
      // @ts-ignore
      data[dateIndex] = d.max_value
    } else {
      // @ts-ignore
      data[dateIndex] = d.avg_value
    }
  })
  return data
}

const organizeTableDataForInstantainus = (arr) => {
  if (arr.length === 0) return
  let minAvgData = arr.map((obj) => obj.min_value)
  let maxAvgData = arr.map((obj) => obj.max_value)
  let avgData = arr.map((obj) => obj.avg_value)
  return {
    minArr: minAvgData,
    maxArr: maxAvgData,
    avgData: avgData
  }
}

function handleProjectCountingDataSet(obj, labels) {
  let data: string[] = []
  obj.data.forEach((d) => {
    const dateIndex = labels.findIndex((l) => l === d.ts_from)
    data[dateIndex] = d.nb_projects
  })
  return data
}

function handleDataSet(obj, labels) {
  let data: string[] = []
  obj.data.forEach((d) => {
    const dateIndex = labels.findIndex((l) => l === d.ts_from)
    data[dateIndex] = d.value
  })
  return data
}

const getColorWithOpacity = (color, opacity) => {
  color = color.replace('#', '')
  let r = parseInt(color.substr(0, 2), 16)
  let g = parseInt(color.substr(2, 2), 16)
  let b = parseInt(color.substr(4, 2), 16)
  let rgba = 'rgba(' + r + ', ' + g + ', ' + b + ', ' + opacity + ')'
  return rgba
}

const getRandomColor = () => {
  let letters = '0123456789ABCDEF'
  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

const getLighterAndDarkerColors = (color) => {
  const r = parseInt(color.slice(1, 3), 16)
  const g = parseInt(color.slice(3, 5), 16)
  const b = parseInt(color.slice(5, 7), 16)
  const lighterR = Math.min(Math.round(r * 1.2), 255)
  const lighterG = Math.min(Math.round(g * 1.2), 255)
  const lighterB = Math.min(Math.round(b * 1.2), 255)
  const lighterColor = `#${lighterR.toString(16).padStart(2, '0')}${lighterG
    .toString(16)
    .padStart(2, '0')}${lighterB.toString(16).padStart(2, '0')}`
  const darkerR = Math.max(Math.round(r * 0.8), 0)
  const darkerG = Math.max(Math.round(g * 0.8), 0)
  const darkerB = Math.max(Math.round(b * 0.8), 0)
  const darkerColor = `#${darkerR.toString(16).padStart(2, '0')}${darkerG
    .toString(16)
    .padStart(2, '0')}${darkerB.toString(16).padStart(2, '0')}`
  return [color, lighterColor, darkerColor]
}

const shortenedNumber = (number) => {
  if (number >= 100000) {
    const formattedNumber = (number / 1000000).toFixed(2)
    const shortenedNumber = formattedNumber.replace(/\.?0+$/, '') + 'M'
    return shortenedNumber
  } else {
    return number.toLocaleString('en-US', {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0
    })
  }
}
